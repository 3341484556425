<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title>Permission according to roles</b-card-sub-title>
    </b-card-body>
    <div class="mx-2">
      <b-table
        striped
        responsive
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox
            disabled
            :checked="data.value"
          />
        </template>

      </b-table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      permissionsData: [],
    }
  },
  mounted() {
    if (this.userData.perm_sections) {
      this.userData.perm_sections.forEach( section => {
        this.permissionsData.push({
          module: section,
          read: true,
          write: true,
          create: true,
          delete: true,
        })
      })
    }
  },
}
</script>

<style>

</style>
